import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import UploadService from '../../service/file-upload.service';
import { Table, Tag, Pagination, Tooltip ,Modal, Input, Button, Form } from 'antd';

function Corp_ConceptNotePayment() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [encRequest, setEncRequest] = useState();
  const [accessCode, setAccessCode] = useState();
  const [totalElements, setTotalElements] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [refreshPage, setRefreshPage] = useState();
  const userCred = $.parseJSON(sessionStorage.getItem('da_userCred'));
  const userRoleAuthMapping = $.parseJSON(sessionStorage.getItem('da_authMapping'));
  const [donatenowmodel, setDonatenowmodel] = useState(false);
  const[amountModel,setAmountModel]= useState(false);
  const[agreementData,setAgreementData]=useState();

  
  const payment = (record) => {
    setAmountModel(true);
    setAgreementData(record)
  };
  const validateAmount = (_, value) => {
    const amount = parseFloat(value);

    if (!value) {
      return Promise.reject("Amount is required");
    } else if (isNaN(amount) || amount < 10000) {
      return Promise.reject("Amount should be greater than Rs. 10000");
    } else {
      return Promise.resolve();
    }
  };
  const onsubmit = (values) => {
    console.log(userCred)
    console.log(agreementData)
const corpDonationUserDetails={
  id : 0,
  entityId :userCred.associatedEntity,
  corpName :userCred.userName,
  email :userCred.userId,
  phone : null,
  ngoId : agreementData.ngoId,
  causeId : agreementData.causeId,
  donationType :"CONCEPT_NOTE_DONATION",
  donatedAmount : values.amount,
  gatewayCreditedAmount : 0.0,
  finalAmountAfterFees : 0.0,
  doaramPlatformFees : 0.0,
  orderId :"",
  paymentMode :"" ,
  status :"" ,
  pan :"" ,
  cert80g:"" ,
  createdDate:"" ,
  createdBy :"",
  updatedDate :"" ,
  updatedBy :""
}

    const PaymentformData = new FormData();
    PaymentformData.append("userCred", JSON.stringify(userCred));
    PaymentformData.append( "userRoleAuthMapping",JSON.stringify(userRoleAuthMapping));
    PaymentformData.append("corpDonationUserDetails", JSON.stringify(corpDonationUserDetails));
    UploadService.create("corp/payments/conceptNote/createOrder", PaymentformData).then(
      (response) => {
        console.log(response.data)
        setEncRequest(response.data.data.encReq);
        setAccessCode(response.data.data.accessCode);
        setAmountModel(false);
        setDonatenowmodel(true);
      }
    );
  }; 

  const Donationcolumns = [
    {
      title: 'Cause',
      dataIndex: 'causeId',
      width: 60,
    },
    {
      title: 'Date(Updated)',
      dataIndex: 'updatedDate',
      width: 90,
      render: (text) => {
        const datePart = text?.substr(0, 10); // Extracts the date part (YYYY-MM-DD)
        return datePart || 'N/A'; // Handle undefined or null dates
      },
    },
    {
      title: 'Concept Note Status',
      dataIndex: 'status',
      width: 90,
      render: (text) => {
        if (text === 'AGREEMENT_COMPLETED') {
          return <Tag color="green" className="status-tag">Completed</Tag>;
        }
        if (text === 'AGREEMENT_CORP_SIGNED') {
          return <Tag color="green" className="status-tag">Approved</Tag>;
        }
        return text;
      },
    },
    {
      title: 'Payment',
      key: 'Upload',
      width: 90,
      render: (record) => (
        <>
          <Tooltip title="Donate for cause">
            <a onClick={() => { 
              payment(record); 
            }}>
              <button className="btn btn-warning btn-sm text-white">
                <i className="fa fa-rupee-sign text-white"></i> Donate
              </button>
            </a>
          </Tooltip>
        </>
      ),
    },
  ];

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const ConceptNoteformData = new FormData();
    ConceptNoteformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteformData.append('page', JSON.stringify(page - 1)); 
    ConceptNoteformData.append('size', JSON.stringify(size));

    UploadService.create('corp/conceptNote/agreements/get', ConceptNoteformData)
      .then((response) => {
        const { content, totalElements } = response.data.data.conceptNoteAgreements;
        setDataSource(content); 
        setTotalElements(totalElements);
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }, [page, size, refreshPage]);

  const filteredData = dataSource.filter(record => record.status === 'AGREEMENT_COMPLETED' || record.status === 'AGREEMENT_CORP_SIGNED' );

  return (
    <div className="col-md-12 row fontfamilynca">
      <section className="content">
        <div className="row">
          <div className="mt-1">
            <Table
              columns={Donationcolumns}
              dataSource={filteredData} 
              style={{ padding: '5px' }}
              pagination={false}
              bordered
              scroll={{ x: 1000 }}
              className="custom-table"
            />
            <Pagination
              current={page}
              pageSize={size}
              onChange={handlePageChange}
              total={totalElements}
              showSizeChanger={false}
            />
          </div>
        </div>
      </section>
      <Modal open={amountModel} closeIcon={false} centered={true} footer={false}>
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "20px",
            borderRadius: "8px",
          }}
        >
          <div className="d-flex justify-content-center">
            <img
              src={require("../../../imges/individual/donate.png")}
              alt="sucess-icon"
            />
          </div>
          <div className="d-flex justify-content-center">
            <h1 className="modalfont">Donate Now</h1>
          </div>
          <Form
            scrollToFirstError={true}
            name="validateOnly"
            onFinish={onsubmit}
          >
            <div>
              <Form.Item
                className="para b themeCol-orange mt-3"
                label="Amount :"
                name="amount"
                rules={[{ validator: validateAmount }]}
              >
                <Input placeholder="Amount" />
              </Form.Item>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Button key="ok" type="primary" onClick={()=>{setAmountModel(false)}}>
                Cancel
              </Button>
              <Button htmlType="submit" className=" ms-3">
                Pay Now
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        title="Proceed for payment"
        centered
        open={donatenowmodel}
        footer={null}
        onCancel={() => setDonatenowmodel(false)}
      >
        <>
          <form
            action="https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
            method="POST"
            name="redirect"
            className="row"
          >
            <input
              type="hidden"
              id="encRequest"
              name="encRequest"
              value={encRequest}
            />
            <input
              type="hidden"
              name="access_code"
              id="access_code"
              value={accessCode}
            />
            <div className="mt-3 ">
              <p>
                {" "}
                With each donation, you're not just giving, but you're becoming
                a part of a story that changes lives.
              </p>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <button
                className="btnnca btn-gold"
                type="submit"
                style={{ padding: "8px", width: "200px" }}
              >
                <i className="fa fa-heart me-1"></i>
                Donate Now
              </button>
            </div>
          </form>
        </>
      </Modal>
     
    </div>
  );
}

export default Corp_ConceptNotePayment;
