import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import UploadService from '../../service/file-upload.service';
import { Table, Tag, Pagination, Modal, Form, Button, Input, Tooltip } from 'antd';

export default function ConceptNoteStatus() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [conceptnodemodel, setConceptnodemodel] = useState(false);
  const [totalElements, setTotalElements] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [recordData, setrecordData] = useState([]);
  const [uploadData, setUploadedData] = useState()
  const [refreshPage, setRefreshPage] = useState()
  const [viewDataModel, setViewDataModel] = useState()
  const userCred = $.parseJSON(sessionStorage.getItem('da_userCred'));
  const userRoleAuthMapping = $.parseJSON(sessionStorage.getItem('da_authMapping'));
  const showModal = (record) => {
    setConceptnodemodel(true);
    setrecordData(record)
  };


  const handleCancel = () => {
    setConceptnodemodel(false);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const onsubmit = (value) => {
    console.log(recordData)
    const conceptnote = {
      ngoId: userCred.associatedEntity,
      corpId: recordData.corpId,
      causeId: recordData.causeId,
      version: 3,
      type: recordData.type,
      status: recordData.status,
      needOfTheCause: value.needOfTheCause,
      programDetails: value.programDetails,
      budget: value.budget,
      expectedOutcome: value.expectedOutcome,
      comments: "Submitted ConceptNote",
      allowUpload: true,
      nextAction: recordData.nextAction,
      createdDate: recordData.createdDate,
      createdBy: recordData.createdBy,
      updatedDate: recordData.updatedDate,
      updatedBy: recordData.updatedBy
    }
    const ConceptNotesendformData = new FormData();
    ConceptNotesendformData.append('userCred', JSON.stringify(userCred));
    ConceptNotesendformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNotesendformData.append('conceptNote', JSON.stringify(conceptnote));

    UploadService.create('ngo/conceptNote/update', ConceptNotesendformData)
      .then((response) => {
        console.log(response.data)
        setConceptnodemodel(false);
        setRefreshPage("0")

      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }

  // Define your table columns
  const Donationcolumns = [
    {
      title: 'Cause',
      dataIndex: 'causeId',
      width: 60,
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      width: 90,
      render: (text) => text  // Ensure that no value is displayed if comments are missing
    },
    {
      title: 'Date(Updated)',
      dataIndex: 'updatedDate',
      width: 90,
      render: (text) => {
        const datePart = text?.substr(0, 10); // Extracts the date part (YYYY-MM-DD)
        return datePart || 'N/A'; // Handle undefined or null dates
      },
    },
    {
      title: 'Concept Note Status',
      dataIndex: 'status',
      width: 90,
      render: (text) => {
        if (text === 'CONCEPT_NOTE_REQUESTED') {
          return <Tag color="orange" className="status-tag">Requested</Tag>;
        }
        if (text === 'CONCEPT_NOTE_SUBMITTED') {
          return <Tag color="orange" className="status-tag"> Submitted</Tag>;
        }
        if (text === 'CONCEPT_NOTE_REJECTED') {
          return <Tag color="orange" className="status-tag">Rejected</Tag>;
        }
        if (text === 'CONCEPT_NOTE_APPROVED') {
          return <Tag color="orange" className="status-tag">Approved</Tag>;
        }
        if (text === "CONCEPT_NOTE_MORE_INFORMATION_REQUESTED") {
          return <Tag color="orange" className="status-tag">Revision Requested</Tag>;
        }
        if (text === "CONCEPT_NOTE_MORE_INFORMATION_SUBMITTED") {
          return <Tag color="orange" className="status-tag">Revision Submitted</Tag>;
        }

        return text;
      },
    },
    {
      title: 'Upload',
      key: 'Upload',
      width: 90,
      render: (record) => {
        if (record.status === 'CONCEPT_NOTE_SUBMITTED' || record.status === 'CONCEPT_NOTE_REJECTED' ||
          record.status === 'CONCEPT_NOTE_APPROVED' || record.status === 'CONCEPT_NOTE_MORE_INFORMATION_SUBMITTED') {
          return (
            <Tooltip title="View Submitted Details">
              <a onClick={() => showUploadedDetails(record)}>
                <button
                  className="btn my-2 btn-sm text-white agreement"
                  style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                >
                  <i className="fas fa-eye"></i> View
                </button>
              </a>
            </Tooltip>
          );
        }

        return (
          <button
                className="btn btn-sm text-white agreement"
                style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                onClick={()=>{showModal(record)}}
              >
                <i className="fa fa-upload"></i> Upload
              </button>
        );
      },
    }

  ];
  const showUploadedDetails = (record) => {
    console.log(record);
    setUploadedData(record)
    setViewDataModel(true)
  }


  useEffect(() => {
    const ConceptNoteformData = new FormData();
    ConceptNoteformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteformData.append('page', JSON.stringify(page - 1)); // Correct 0-indexed page
    ConceptNoteformData.append('size', JSON.stringify(size));


    UploadService.create('ngo/conceptNote/get', ConceptNoteformData)
      .then((response) => {
        const { content, totalElements } = response.data.data.conceptNote;
        setDataSource(content);
        setTotalElements(totalElements);
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }, [page, size, refreshPage]);
  return (
    <>
      <div className="col-md-12 row fontfamilynca p-2">

        <section className="content">
          <div className="row">
            <div className="mt-3">
              <Table
                columns={Donationcolumns}
                dataSource={dataSource}
                style={{ padding: '5px' }}
                pagination={false}
                bordered
                scroll={{ x: 1000 }}
                className="custom-table"
              />
              <Pagination
                current={page}
                pageSize={size}
                onChange={handlePageChange}
                total={totalElements}
                showSizeChanger={false}
              />
            </div>
          </div>
        </section>
      </div>
      <Modal
        closeIcon={false}
        centered={true}
        open={conceptnodemodel}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleCancel}>
              Cancel
            </Button>
          </div>,
        ]}
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmit}>
          <p>Please provide Following Deatils( max length 150):</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="needOfTheCause"
              rules={[
                {
                  required: true,
                  message: "Need Of The Cause is required",
                },
              ]}
            >
              <Input.TextArea
                style={{ height: 100, resize: "none" }}
                placeholder="Need Of The Cause"
                maxLength={150}
                showCount
              />
            </Form.Item>


            <Form.Item
              className="para b themeCol-orange mt-3"
              name="programDetails"
              rules={[
                {
                  required: true,
                  message: "Program Details Note is required",
                },
              ]}
            >
              <Input.TextArea
                style={{ height: 100, resize: "none" }}
                placeholder="Program Details "
                maxLength={150}
                showCount
              />
            </Form.Item>



            <Form.Item
              className="para b themeCol-orange mt-3"
              name="expectedOutcome"
              rules={[
                {
                  required: true,
                  message: "Expected Outcome  is required",
                },
              ]}
            >
              <Input.TextArea
                style={{ height: 100, resize: "none" }}
                placeholder="Expected Outcome "
                maxLength={100}
                showCount
              />
            </Form.Item>

            <Form.Item
              className="para b themeCol-orange mt-3"
              name="budget"
              rules={[
                {
                  required: true,
                  message: "Budget  is required",
                },
              ]}
            >
              <Input
                type="number"
                placeholder="Budget "
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <Button htmlType="submit" className="bg-yellowgra">
              Send +
            </Button>
          </div>
        </Form>
      </Modal>
      <Modal
        closeIcon={false}
        centered={true}
        open={viewDataModel}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button
              key="ok"
              type="primary"
              onClick={() => setViewDataModel(false)}
              icon={<i className="fas fa-times-circle" style={{ marginRight: 8 }} />}
            >
              Close
            </Button>
          </div>
        ]}
        title={<span><i className="fas fa-file-alt" style={{ marginRight: 8 }} />ConceptNote Details:</span>}
      >
        <div style={{ marginBottom: '16px' }} className='mt-3'>
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            <i className="fas fa-lightbulb" style={{ marginRight: 8, color: '#FFA500' }} />
            Need Of The Cause:
          </span>
          <span>{uploadData?.needOfTheCause}</span>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            <i className="fas fa-tasks" style={{ marginRight: 8, color: '#007bff' }} />
            Program Details:
          </span>
          <span>{uploadData?.programDetails}</span>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            <i className="fas fa-chart-line" style={{ marginRight: 8, color: '#28a745' }} />
            Expected Outcome:
          </span>
          <span>{uploadData?.expectedOutcome}</span>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            <i className="fas fa-money-bill-wave" style={{ marginRight: 8, color: '#17a2b8' }} />
            Budget:
          </span>
          <span>₹{uploadData?.budget}</span>
        </div>
      </Modal>

    </>
  )
}
