import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import UploadService from '../../service/file-upload.service';
import { Table, Tag, Pagination, Modal, Form, Button, Input, Tooltip } from 'antd';
function Corp_ConceptNoteStatus() {

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [acceptmodel, setAcceptmodel] = useState(false);
  const [rejectmodel, setRejectmodel] = useState(false);
  const [addinfomodel, setAddinfomodel] = useState(false);
  const [viewDataModel, setViewDataModel] = useState()
  const [uploadData, setUploadedData] = useState()
  const [recordData, setRecordData] = useState(8);
  const [refreshPage, setRefreshPage] = useState()

  const userCred = $.parseJSON(sessionStorage.getItem('da_userCred'));
  const userRoleAuthMapping = $.parseJSON(sessionStorage.getItem('da_authMapping'));

  useEffect(() => {
    const ConceptNoteformData = new FormData();
    ConceptNoteformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteformData.append('page', JSON.stringify(page - 1));
    ConceptNoteformData.append('size', JSON.stringify(size));

    UploadService.create('corp/conceptNote/get', ConceptNoteformData)
      .then((response) => {
        const { content, totalElements } = response.data.data.conceptNote;
        setDataSource(content);
        setTotalElements(totalElements);
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }, [page, size, refreshPage]);

  const Donationcolumns = [
    {
      title: 'Cause',
      dataIndex: 'causeId',
      width: 60,
    },
    {
      title: 'Comments',
      dataIndex: 'comments',
      width: 90,
      render: (text) => text
    },
    {
      title: 'Date(Updated)',
      dataIndex: 'updatedDate',
      width: 90,
      render: (text) => {
        const datePart = text?.substr(0, 10);
        return datePart || 'N/A';
      },
    },
    {
      title: 'Concept Note Status',
      dataIndex: 'status',
      width: 90,
      render: (text) => {
        if (text === 'CONCEPT_NOTE_REQUESTED') {
          return <Tag color="orange" className="status-tag">Requested</Tag>;
        }
        if (text === 'CONCEPT_NOTE_SUBMITTED') {
          return <Tag color="orange" className="status-tag"> Submitted</Tag>;
        }
        if (text === 'CONCEPT_NOTE_REJECTED') {
          return <Tag color="orange" className="status-tag">Rejected</Tag>;
        }
        if (text === 'CONCEPT_NOTE_APPROVED') {
          return <Tag color="orange" className="status-tag">Approved</Tag>;
        }
        if (text === "CONCEPT_NOTE_MORE_INFORMATION_REQUESTED") {
          return <Tag color="orange" className="status-tag">Revision Requested</Tag>;
        }
        if (text === "CONCEPT_NOTE_MORE_INFORMATION_SUBMITTED") {
          return <Tag color="orange" className="status-tag">Revision Submitted</Tag>;
        }
        return text;
      },
    },
    {
      title: 'View Concept Note',
      width: 90,
      render: (record) => {
        if (record.status === 'CONCEPT_NOTE_REQUESTED' || record.status === "CONCEPT_NOTE_MORE_INFORMATION_REQUESTED") {
          return (
            <Tooltip title="Waiting for NGO to Upload Details">
              <button
                className="btn btn-sm text-white agreement"
                style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
              >
               <i className="fas fa-ban text-white"></i> Waiting...
              </button>
            </Tooltip>
          );
        }
        return (
          <Tooltip title="View Submitted Details" >
            <a onClick={() => showUploadedDetails(record)}>
              <button
                className="btn my-2 btn-sm text-white agreement"
                style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
              >
                <i className="fas fa-eye"></i> View
              </button>
            </a>
          </Tooltip>
        );
      },

    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      render: (record) => {
        if (record.status === 'CONCEPT_NOTE_REQUESTED' || record.status === 'CONCEPT_NOTE_REJECTED' || record.status === 'CONCEPT_NOTE_APPROVED' || record.status === "CONCEPT_NOTE_MORE_INFORMATION_REQUESTED") {
          // Render the special button if status is 'CONCEPT_NOTE_REQUESTED'
          return (
            <>
              <span className="btn btn-green btn-sm para me-1">
                <i className="fas fa-ban text-white"></i>
              </span>
              <span className="btn btn-red btn-sm para me-1">
                <i className="fas fa-ban text-white"></i>
              </span>
            </>
          );
        }
        return (
          <div className="p-2 d-flex justify-content-between align-items-center">
            <div>
              <button
                className="btn btn-green btn-sm my-2 agreement"
                onClick={() => acceptConteptnote(record)}
              >
                <i className="fa fa-check text-white"></i> Approve
              </button>
              <button
                className="btn btn-orange btn-sm agreement"
                onClick={() => addinfo(record)}
              >
                <i className="fa fa-plus text-white"></i> Send Revision
              </button>


              <button
                className="btn btn-red btn-sm my-2 agreement"
                onClick={() => rejectConteptnote(record)}
              >
                <i className="fa fa-ban text-white"></i> Reject
              </button>
            </div>



          </div>
        );
      },
    }


  ];
  const showUploadedDetails = (record) => {
    console.log(record);
    setUploadedData(record)
    setViewDataModel(true)
  }


  const acceptConteptnote = (record) => {
    const ConceptNoteAcceptformData = new FormData();
    ConceptNoteAcceptformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteAcceptformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteAcceptformData.append('conceptNote', JSON.stringify(record));
    UploadService.create('corp/conceptNote/approve', ConceptNoteAcceptformData)
      .then((response) => {
        console.log(response.data.data)
        setAcceptmodel(true)
        setRefreshPage("1")
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);

      });

  }
  const addinfo = (record) => {
    setAddinfomodel(true)
    setRecordData(record)

  }
  const rejectConteptnote = (record) => {
    setRejectmodel(true)
    setRecordData(record)
  }

  const onsubmitaddinfo = (values) => {
    const conceptNote = {
      ngoId: recordData.ngoId,
      corpId: recordData.corpId,
      causeId: recordData.causeId,
      version: recordData.version,
      type: recordData.type,
      status: recordData.status,
      comments: values.reason,
      allowUpload: recordData.allowUpload,
      nextAction: recordData.nextAction,
      createdDate: recordData.createdDate,
      createdBy: recordData.createdBy,
      updatedDate: recordData.updatedDate,
      updatedBy: recordData.updatedBy
    }
    const ConceptNoteAddinfoformData = new FormData();
    ConceptNoteAddinfoformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteAddinfoformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteAddinfoformData.append('conceptNote', JSON.stringify(conceptNote));
    UploadService.create('corp/conceptNote/additionalInfo', ConceptNoteAddinfoformData)
      .then((response) => {
        setAddinfomodel(false)
        setRefreshPage("2")
        Modal.info({
          content: (
            <div>
              <p> Revised ConceptNote Requested sucessfully</p>
            </div>
          ),
        });
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });

  }

  const onsubmitreject = (values) => {
    const conceptNote = {
      ngoId: recordData.ngoId,
      corpId: recordData.corpId,
      causeId: recordData.causeId,
      version: recordData.version,
      type: recordData.type,
      status: recordData.status,
      comments: values.reason,
      allowUpload: recordData.allowUpload,
      nextAction: recordData.nextAction,
      createdDate: recordData.createdDate,
      createdBy: recordData.createdBy,
      updatedDate: recordData.updatedDate,
      updatedBy: recordData.updatedBy
    }
    const ConceptNoteRejectformData = new FormData();
    ConceptNoteRejectformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteRejectformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteRejectformData.append('conceptNote', JSON.stringify(conceptNote));
    UploadService.create('corp/conceptNote/reject', ConceptNoteRejectformData)
      .then((response) => {
        setRefreshPage("0")
        setRejectmodel(false)
        Modal.info({
          content: (
            <div>
              <p> ConceptNote Rejected Sucessfully.</p>
            </div>
          ),
        });
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });

  }


  const handlePageChange = (page) => {
    setPage(page);
  };

  return (
    <div className="col-md-12 row fontfamilynca p-2">
      <section className="content">
        <div className="row">
          <div className="ß">
            <Table
              columns={Donationcolumns}
              dataSource={dataSource}
              style={{ padding: '5px' }}
              pagination={false}
              bordered
              scroll={{ x: 1000 }}
              className="custom-table"
            />
            <Pagination
              current={page}
              pageSize={size}
              onChange={handlePageChange}
              total={totalElements}
              showSizeChanger={false}
            />
          </div>
        </div>
      </section>
      <Modal
        visible={acceptmodel}
        footer={null}
        closeIcon={false}
        centered={true}
        okText="OK"
      >
        <p className="d-flex  justify-content-center">Concept Note Accepted Successfully.</p>
        <div className="d-flex  justify-content-center">
          <Button className="btn-green mt-3" onClick={() => { setAcceptmodel(false) }}>
            ok
          </Button>
        </div>
      </Modal>
      <Modal
        visible={rejectmodel}
        footer={null}
        closeIcon={false}
        centered={true}
        okText="OK"
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmitreject}>
          <p>Please provide  reason for Rejecting Concept Note.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Rejecting Conceptnote  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <button htmlType="submit" className="btn btn-green btn-sm ms-2">
              <i className="fa fa-check text-white"></i> Send
            </button>
            <button className="btn btn-red btn-sm ms-2" onClick={() => setRejectmodel(false)}>
              <i className="fa fa-ban text-white"></i> Close
            </button>

          </div>
        </Form>
      </Modal>
      <Modal
        visible={addinfomodel}
        footer={null}
        centered={true}
        closeIcon={false}
        okText="OK"
      >
        <Form scrollToFirstError={true} name="validateOnly" onFinish={onsubmitaddinfo}>
          <p>Please provide  reason for the Revisied Conceptnote.</p>
          <div>
            <Form.Item
              className="para b themeCol-orange mt-3"
              name="reason"
              rules={[
                {
                  required: true,
                  message: "Reason is required",
                },
              ]}
            >
              <Input.TextArea
                showCount
                maxLength={100}
                style={{ height: 100, resize: "none" }}
                placeholder="Reason for the Revisied Conceptnote  in 100 words"
              />
            </Form.Item>
          </div>
          <div className="d-flex  justify-content-center">
            <button htmlType="submit" className="btn btn-green btn-sm ms-2">
              <i className="fa fa-check text-white"></i> Send
            </button>
            <button className="btn btn-red btn-sm ms-2" onClick={() => setAddinfomodel(false)}>
              <i className="fa fa-ban text-white"></i> Close
            </button>

          </div>
        </Form>
      </Modal>
      <Modal
        closeIcon={false}
        centered={true}
        open={viewDataModel}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button
              key="ok"
              type="primary"
              onClick={() => setViewDataModel(false)}
              icon={<i className="fas fa-times-circle" style={{ marginRight: 8 }} />}
            >
              Close
            </Button>
          </div>
        ]}
        title={<span><i className="fas fa-file-alt" style={{ marginRight: 8 }} />ConceptNote Details:</span>}
      >
        <div style={{ marginBottom: '16px' }} className='mt-3'>
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            <i className="fas fa-lightbulb" style={{ marginRight: 8, color: '#FFA500' }} />
            Need Of The Cause:
          </span>
          <span>{uploadData?.needOfTheCause}</span>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            <i className="fas fa-tasks" style={{ marginRight: 8, color: '#007bff' }} />
            Program Details:
          </span>
          <span>{uploadData?.programDetails}</span>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            <i className="fas fa-chart-line" style={{ marginRight: 8, color: '#28a745' }} />
            Expected Outcome:
          </span>
          <span>{uploadData?.expectedOutcome}</span>
        </div>

        <div style={{ marginBottom: '16px' }}>
          <span style={{ color: '#333', fontWeight: 'bold' }}>
            <i className="fas fa-money-bill-wave" style={{ marginRight: 8, color: '#17a2b8' }} />
            Budget:
          </span>
          <span>₹{uploadData?.budget}</span>
        </div>
      </Modal>

    </div>
  )
}

export default Corp_ConceptNoteStatus