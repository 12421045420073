import { useState } from 'react';
import { Steps } from 'antd';
import ConceptNoteAgreements from './ConceptNoteAgreements';
import ConceptNoteStatus from './ConceptNoteStatus';
import ConceptNotePayment from './ConceptNotePayment';

function ConceptNote() {

  const [current, setCurrent] = useState(0);

  const onChange = (value) => {
    console.log('onChange:', value);
    setCurrent(value);
  };

  const renderStepContent = () => {
    switch (current) {
      case 0:
        return <ConceptNoteStatus />;
      case 1:
        return <ConceptNoteAgreements />;
      case 2:
        return <ConceptNotePayment />;
      default:
        return null;
    }
  };

  return (
    <>
      <div className="p-3 mt-3">
        <Steps
          current={current}
          onChange={onChange}
          items={[
            {
              title: (
                <div
                  style={{
                    color: current === 0 ? '#d69228' : '#694155',
                    fontWeight: current === 0 ? 'bold' : 'normal',
                  }}
                  onMouseEnter={(e) => (e.target.style.color = '#694155')}
                  onMouseLeave={(e) => (e.target.style.color = current === 0 ? '#d69228' : '#694155')}
                >
                  Status
                </div>
              ),
              icon: (
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    // Fix the error: Corrected border style with template literal
                    border: `2px solid ${current === 0 ? '#d69228' : '#694155'}`,
                    color: current === 0 ? '#d69228' : '#694155',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 18,
                    fontWeight: current === 0 ? 'bold' : 'normal',
                  }}
                >
                  <i className='fa fa-tasks'></i>
                </div>
              ),
            },
            {
              title: (
                <div
                  style={{
                    color: current === 1 ? '#d69228' : '#694155',
                    fontWeight: current === 1 ? 'bold' : 'normal',
                  }}
                  onMouseEnter={(e) => (e.target.style.color = '#694155')}
                  onMouseLeave={(e) => (e.target.style.color = current === 1 ? '#d69228' : '#694155')}
                >
                  Agreements
                </div>
              ),
              icon: (
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    // Fix the error: Corrected border style with template literal
                    border: `2px solid ${current === 1 ? '#d69228' : '#694155'}`,
                    color: current === 1 ? '#d69228' : '#694155',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 18,
                    fontWeight: current === 1 ? 'bold' : 'normal',
                  }}
                >
                  <i className='fa fa-file-signature'></i>
                </div>
              ),
            },
            {
              title: (
                <div
                  style={{
                    color: current === 2 ? '#d69228' : '#694155',
                    fontWeight: current === 2 ? 'bold' : 'normal',
                  }}
                  onMouseEnter={(e) => (e.target.style.color = '#694155')}
                  onMouseLeave={(e) => (e.target.style.color = current === 2 ? '#d69228' : '#694155')}
                >
                  Payment
                </div>
              ),
              icon: (
                <div
                  style={{
                    width: 50,
                    height: 50,
                    borderRadius: '50%',
                    // Fix the error: Corrected border style with template literal
                    border: `2px solid ${current === 2 ? '#d69228' : '#694155'}`,
                    color: current === 2 ? '#d69228' : '#694155',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontSize: 18,
                    fontWeight: current === 2 ? 'bold' : 'normal',
                  }}
                >
                  <i className='fa fa-credit-card'></i>
                </div>
              ),
            },
          ]}
        />
        <div style={{ marginTop: '20px' }}>{renderStepContent()}</div>
      </div>
    </>
  );
}

export default ConceptNote;
