import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import UploadService from '../../service/file-upload.service';
import { Table, Tag, Pagination, Modal, Form, Button, Input, Tooltip, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';

function ConceptNoteAgreements() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [refreshPage, setRefreshPage] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [fileList, setFileList] = useState([]); // Store the uploaded file list
  const userCred = $.parseJSON(sessionStorage.getItem('da_userCred'));
  const userRoleAuthMapping = $.parseJSON(sessionStorage.getItem('da_authMapping'));
  const allowedFileTypes = ["application/pdf"];



  const DownloadConceptNote = (recordData) => {
    const DownloadformData = new FormData();
    DownloadformData.append('userCred', JSON.stringify(userCred));
    DownloadformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    DownloadformData.append('conceptNoteAgreement', JSON.stringify(recordData));
    UploadService.filedownload('ngo/conceptNote/agreement/ngo/download', DownloadformData)
      .then((response) => {
        viewBlob(response.data);

      })
      .catch((error) => {
        message.error('File Download failed.');
      });

  };

  const DownloadCropConceptNote = (recordData) => {
    const DownloadformData = new FormData();
    DownloadformData.append('userCred', JSON.stringify(userCred));
    DownloadformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    DownloadformData.append('conceptNoteAgreement', JSON.stringify(recordData));
    UploadService.filedownload('ngo/conceptNote/agreement/corp/download', DownloadformData)
      .then((response) => {
        viewBlob(response.data);
      })
      .catch((error) => {
        message.error('File Download failed.');
      });

  };
  function viewBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    }
    else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }

  const UploadConceptNote = (recordData) => {
    setRecordData(recordData);
    setIsModalVisible(true);
  };
  const checkFileType = (file) => {
    const isFileTypeAllowed = allowedFileTypes.includes(file.type);
    if (!isFileTypeAllowed) {
      message.error("You can only upload JPG, JPEG, or PNG files!");
    }
    return isFileTypeAllowed;
  };

  const customBeforeUpload = (file) => {
    const isFileTypeAllowed = checkFileType(file);
    return isFileTypeAllowed ? true : Upload.LIST_IGNORE;
  };

  const onsubmit = (values) => {
    const updatedRecordData = {
      ...recordData,
      ngoAgreementFileName: values.Agreement.file.name
    };
    const formData = new FormData();
    formData.append('userCred', JSON.stringify(userCred));
    formData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    formData.append('conceptNoteAgreement', JSON.stringify(updatedRecordData));
    formData.append('file', values.Agreement.file.originFileObj);

    UploadService.create('ngo/conceptNote/agreement/upload', formData)
      .then((response) => {
        message.success('File uploaded successfully!');
        setIsModalVisible(false);
        setRefreshPage(!refreshPage);
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        message.error('File upload failed.');
      });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const Donationcolumns = [
    {
      title: 'Cause',
      dataIndex: 'causeId',
      width: 60,
      align: 'center',
    },
    {
      title: 'Date(Updated)',
      dataIndex: 'updatedDate',
      width: 90,
      align: 'center',
      render: (text) => {
        const datePart = text?.substr(0, 10);
        return datePart || 'N/A';
      },
    },
    {
      title: 'Agreement Status',
      dataIndex: 'status',
      width: 90,
      align: 'center',
      render: (text) => {
        const statusMap = {
          'CONCEPT_NOTE_REQUESTED': 'Requested',
          'CONCEPT_NOTE_SUBMITTED': 'Submitted',
          'CONCEPT_NOTE_REJECTED': 'Rejected',
          'CONCEPT_NOTE_APPROVED': 'Approved',
          'CONCEPT_NOTE_MORE_INFORMATION_REQUESTED': 'Revision Requested',
          'CONCEPT_NOTE_MORE_INFORMATION_SUBMITTED': 'Revision Submitted',
        };
        return <Tag color="orange">{statusMap[text] || text}</Tag>;
      },
    },
    {
      title: 'Upload',
      key: 'Upload',
      width: 100,
      align: 'center',
      render: (record) => {
        // Check the record status
        const status = record.status;
        const isRestrictedStatus = [
          'AGREEMENT_COMPLETED',
          'AGREEMENT_REJECTED',
          'AGREEMENT_CORP_SIGNED',
          'AGREEMENT_NGO_SIGNED',
        ].includes(status);

        // Check if ngoAgreementFileName is null
        const isNgoAgreementFileNameNull = !record.ngoAgreementFileName;

        return (
          <>
            {/* Show Upload button if status is not restricted, otherwise show disabled button */}
            {!isRestrictedStatus ? (
              <Tooltip title="Upload">
                <a onClick={() => { UploadConceptNote(record) }}>
                  <button
                    className="btn btn-sm text-white agreement"
                    style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
                  >
                    <i className="fa fa-upload"></i> Upload
                  </button>
                </a>
              </Tooltip>
            ) : (
              null
            )}

            {/* Show View button only if ngoAgreementFileName is not null */}
            {!isNgoAgreementFileNameNull && (
              <Tooltip title="Download NGO Agreement">
                <a onClick={() => { DownloadConceptNote(record) }}>
                  <button
                    className="btn my-2 btn-sm text-white agreement"
                    style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                  >
                    <i className="fas fa-eye"></i> View
                  </button>
                </a>
              </Tooltip>
            )}
          </>
        );
      },
    },
    {
      title: 'Corporate Agreement',
      key: 'Upload',
      width: 90,
      align: 'center',
      render: (record) => {
        // Check if corpAgreementFileName is null
        const isCorpAgreementFileNameNull = !record.corpAgreementFileName;

        return (
          <>
            {/* Show Download button only if corpAgreementFileName is not null */}
            {!isCorpAgreementFileNameNull ? (
              <Tooltip title="Download Corp Agreement">
                <a onClick={() => { DownloadCropConceptNote(record) }}>
                <button
                className="btn btn-sm text-white agreement"
                style={{ backgroundColor: '#28a745', borderColor: '#28a745' }}
              >
                <i className="fa fa-download"></i> Download
              </button>
                </a>
              </Tooltip>
            ) : (
              <Tooltip title="Waiting for Corp to sign Agreement">
               
                  <button className="btn btn-warning btn-sm text-white">
                    <i className="fa fa-download"></i> Waiting
                  </button>
               
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];




  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const ConceptNoteformData = new FormData();
    ConceptNoteformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteformData.append('page', JSON.stringify(page - 1)); // Correct 0-indexed page
    ConceptNoteformData.append('size', JSON.stringify(size));

    UploadService.create('ngo/conceptNote/agreements/get', ConceptNoteformData)
      .then((response) => {
        const { content, totalElements } = response.data.data.conceptNoteAgreements;
        setDataSource(content);
        setTotalElements(totalElements);
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }, [page, size, refreshPage]);

  return (
    <div className="col-md-12 row fontfamilynca p-2">
      <section className="content">
        <div className="row">
          <div className="mt-1">
            <Table
              columns={Donationcolumns}
              dataSource={dataSource}
              style={{ padding: '5px' }}
              pagination={false}
              bordered
              scroll={{ x: 1000 }}
              className="custom-table"
            />
            <Pagination
              current={page}
              pageSize={size}
              onChange={handlePageChange}
              total={totalElements}
              showSizeChanger={false}
            />
          </div>
        </div>
      </section>

      {/* Modal for uploading concept note agreement */}
      <Modal
        title="Upload Concept Note Agreement"
        visible={isModalVisible}
        closeIcon={false}
        footer={null} // Hide default footer
        onCancel={handleModalCancel} // Close on cancel
      >
        <Form onFinish={onsubmit}>
          <Form.Item
            name="Agreement"
            label="Agreement PDF"
            className='mt-4'
            rules={[{ required: true, message: "Agreement PDF is required" }]}
          >
            <Upload
              maxCount={1}
              accept=".pdf"
              beforeUpload={customBeforeUpload}
              onChange={handleFileChange}
              fileList={fileList} // Bind the file list here
            >
              <Button block icon={<UploadOutlined />}>
                Click to upload
              </Button>
            </Upload>
          </Form.Item>
          <div className="d-flex justify-content-center mt-4">
            <Button
              key="ok"
              className='mx-2 btn-green'
              htmlType="submit"
              icon={<i className="fas fa-check-circle" style={{ marginRight: 8 }} />}
            >
              Submit
            </Button>
            <Button
              key="cancel"
              className=' btn-red'
              onClick={handleModalCancel}
              icon={<i className="fas fa-times-circle" style={{ marginRight: 8 }} />}
            >
              Close
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
}

export default ConceptNoteAgreements;
