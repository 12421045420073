import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import UploadService from "../../service/file-upload.service";
import { Country, State, City } from "country-state-city";
import SelectReact from "react-select";
import moment from "moment";
import Loading from "../../assets/loading-circle.gif";

import {
  Form,
  Input,
  Button,
  message,
  Select,
  Upload,
  Modal,
  Tabs,
  DatePicker,
} from "antd";

import { UploadOutlined } from "@ant-design/icons";
import $ from "jquery";
const category = [
  "Elderly",
  "Animals",
  "Children",
  "Cultural Heritage",
  "Education",
  "Employment",
  "Environment",
  "Disaster Relief Works",
  "Others",
  "Women Empowerment",
  "Community Mobilization",
  "Rural Development",
  "Sustainable Livelihood",
  "Differently Abled",
  "Advocacy",
];

function CauseDetails() {
  const { TabPane } = Tabs;
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [blur, setBlur] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [selectedItems, setSelectedItems] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
  const allowedFileTypeswithpdf = [
    "image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
  ];

  const filteredOptions = category.filter((o) => !selectedItems.includes(o));
  const userCred = $.parseJSON(sessionStorage.getItem("da_userCred"));

  const userRoleAuthMapping = $.parseJSON(
    sessionStorage.getItem("da_authMapping")
  );

  const handleOk = () => {
    navigate("/NGO_Cause");
  };
  const checkFileType = (file) => {
    const isFileTypeAllowed = allowedFileTypes.includes(file.type);
    if (!isFileTypeAllowed) {
      message.error("You can only upload JPG, JPEG, or PNG files!");
    }
    return isFileTypeAllowed;
  };

  const customBeforeUpload = (file) => {
    const isFileTypeAllowed = checkFileType(file);
    return isFileTypeAllowed ? true : Upload.LIST_IGNORE;
  };

  //////////////
  const checkFileTypewithpdf = (file) => {
    const isFileTypeAllowed = allowedFileTypeswithpdf.includes(file.type);
    if (!isFileTypeAllowed) {
      message.error("You can only upload JPG, JPEG, or PNG files!");
    }
    return isFileTypeAllowed;
  };

  const customBeforeUploadwithpdf = (file) => {
    const isFileTypeAllowed = checkFileTypewithpdf(file);
    return isFileTypeAllowed ? true : Upload.LIST_IGNORE;
  };

  const onsubmit = (values) => {
    setBlur(true);
    setLoading(true);
    const formDataWithFiles = new FormData();
    let images = [];
    let supportiveDocument = [];

    images.push(values.galleryimage0.file.name);
    images.push(values.galleryimage1.file.name);
    images.push(values.galleryimage2.file.name);
    supportiveDocument.push(values.Supportivedocument1.file.name);
    supportiveDocument.push(values.Supportivedocument2.file.name);

    let cause = {
      entityId: "",
      causeId: "",
      version: "",
      title: values.title,
      description: values.description,
      category: values.category.toString(),
      goalType: values.goalType,
      goalAmount: Number(values.goalAmount),
      nonFundGoalDesc: "",
      receivedAmount: 0,
      nonFundGoalStatus: "",
      startDate: values.startDate,
      endDate: values.endDate,
      city: values.city.name,
      state: values.state.name,
      country: values.country.name,
      status: "",
      causeLink: "",
      images: images.toString(),
      supportiveDocs: supportiveDocument.toString(),
      comments: "",
      createdBy: "",
      updatedBy: "",
      createdDate: "",
      updatedDate: "",
    };

    formDataWithFiles.append("cause", JSON.stringify(cause));
    formDataWithFiles.append("userCred", JSON.stringify(userCred));
    formDataWithFiles.append(
      "userRoleAuthMapping",
      JSON.stringify(userRoleAuthMapping)
    );

    for (let i = 0; i <= 2; i++) {
      const key = `galleryimage${i}`;
      const galleryFile = values[key]?.file?.originFileObj;

      if (galleryFile) {
        formDataWithFiles.append("images", galleryFile);
      }
    }

    for (let i = 1; i <= 2; i++) {
      const key = `Supportivedocument${i}`;
      const supportiveDocumentFile = values[key]?.file?.originFileObj;

      if (supportiveDocumentFile) {
        formDataWithFiles.append("supportiveDocs", supportiveDocumentFile);
      }
    }

    UploadService.create("/ngo/cause/create", formDataWithFiles).then(
      (response) => {
        setBlur(false);
        setLoading(false);
        let logSts = response.data.message.messageType;

        if (logSts == "Success") {
          // window.toastr.warning("cause Created Succesfully");
          setIsModalOpen(true);
        } else {
          window.toastr.warning(
            response.data.message.messageDetails[0].responseMessage
          );
        }
      }
    );
  };

  const disabledStartDate = (current) => {
    return current && current < moment().startOf("day");
  };

  const disabledEndDate = (current) => {
    const startDate = form.getFieldValue("startDate");
    return (
      current &&
      (current < moment(startDate).startOf("day") ||
        current < moment().startOf("day"))
    );
  };

  const validateEndDate = (_, value) => {
    const startDate = form.getFieldValue("startDate");
    if (value && startDate && value.isBefore(startDate)) {
      return Promise.reject(new Error("End date must be after start date"));
    }
    return Promise.resolve();
  };

  return (
    <>
      {loading && (
        <div className="loader">
          <img src={Loading} alt="Loading..." />
        </div>
      )}
      <div className={blur ? "blur" : ""}>
        <div className="col-md-12 row fontfamilynca">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-8 row">
                  <div className="col">
                    <NavLink to="/NGO_Cause">
                      <button className="btnnca btn-pink btn-sm mt-3">
                        <i className="fa fa-arrow-left"></i> Back To Cause
                      </button>
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-8 mt-1">
                  <h5 className="m-0 tableheading">&nbsp;Create New Cause</h5>
                </div>
              </div>
            </div>
          </div>

          <section className="content">
            <div className="container-fluid">
              <Tabs defaultActiveKey="1" type="card" large>
                <TabPane tab="Cause Details" key="1">
                  <div className="glasscard">
                    <Form
                      autoComplete="off"
                      form={form}
                      onFinish={onsubmit}
                      labelCol={{ span: 4 }}
                      wrapperCol={{
                        span: 17,
                      }}
                    >
                      <legend className="para b themeCol-orange mx-3 mt-2">
                        Cause Details:{" "}
                      </legend>
                      <div className="mt-4">
                        <Form.Item
                          className="para b themeCol-orange"
                          name="category"
                          label="Category"
                          rules={[
                            {
                              required: true,
                              message: "Category is required",
                            },
                          ]}
                        >
                          <Select
                            mode="multiple"
                            placeholder="--NGO Category--"
                            value={selectedItems}
                            onChange={setSelectedItems}
                            style={{
                              width: "100%",
                            }}
                            options={filteredOptions.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="title"
                          label="Cause Title"
                          rules={[
                            {
                              required: true,
                              message: "Cause title is required",
                            },
                          ]}
                        >
                          <Input
                            size="medium"
                            placeholder="Enter Cause Title"
                          />
                        </Form.Item>
                      </div>
                      <div className="upload">
                        <Form.Item
                          className=""
                          name="galleryimage0"
                          label="Campaign Image"
                          rules={[
                            {
                              required: true,
                              message: "Campaign Image is required",
                            },
                          ]}
                        >
                          <Upload
                            maxCount={1}
                            accept=".jpeg,.png,.jpg"
                            beforeUpload={customBeforeUpload}
                          >
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>
                      <legend className="para b themeCol-orange mx-3 mt-2">
                        Goal Details:{" "}
                      </legend>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="goalType"
                          label="Goal Type"
                          rules={[
                            {
                              required: true,
                              message: "goal type is required",
                            },
                          ]}
                          hasFeedback
                        >
                          <Select
                            placeholder="-Select Goal-"
                            options={[
                              {
                                value: "Funds",
                                label: "Funds",
                              },
                              {
                                value: "Transport",
                                label: "Transport",
                              },
                              {
                                value: "Food",
                                label: "Food",
                              },
                              {
                                value: "Facility",
                                label: "Facility",
                              },
                              {
                                value: "Clothes",
                                label: "Clothes",
                              },
                              {
                                value: "Others",
                                label: "Others",
                              },
                            ]}
                          ></Select>
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          name="goalAmount"
                          label="Goal Amount(₹)"
                          rules={[
                            {
                              required: true,
                              message: "Amout is required",
                            },
                          ]}
                        >
                          <Input
                            type="number"
                            placeholder="Amount In Rupees"
                            style={{ width: "300px" }}
                          />
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          className=""
                          name="startDate"
                          label="Start Date"
                          rules={[
                            {
                              required: true,
                              message: "Start required",
                            },
                          ]}
                        >
                          <DatePicker disabledDate={disabledStartDate} />
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          className=""
                          name="endDate"
                          label="End Date"
                          rules={[
                            {
                              required: true,
                              message: "End Duration required",
                            },
                            {
                              validator: validateEndDate,
                            },
                          ]}
                        >
                          <DatePicker disabledDate={disabledEndDate} />
                        </Form.Item>
                      </div>

                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="description"
                          label="Cause Description"
                          rules={[
                            {
                              required: true,
                              message: "enter cause description",
                            },
                          ]}
                        >
                          <Input.TextArea
                            showCount
                            maxLength={250}
                            style={{ height: 200, resize: "none" }}
                            placeholder="Enter Cause  Description(250 words)"
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="country"
                          label="Country"
                          rules={[
                            {
                              required: true,
                              message: "Country is required",
                            },
                          ]}
                        >
                          <SelectReact
                            isClearable
                            options={Country.getAllCountries()}
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedCountry}
                            onChange={(item) => {
                              setSelectedCountry(item);
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="state"
                          label="State"
                          rules={[
                            {
                              required: true,
                              message: "State is required",
                            },
                          ]}
                        >
                          <SelectReact
                            isClearable
                            options={State?.getStatesOfCountry(
                              selectedCountry?.isoCode
                            )}
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedState}
                            onChange={(item) => {
                              setSelectedState(item);
                            }}
                          />
                        </Form.Item>
                      </div>
                      <div>
                        <Form.Item
                          className="para b themeCol-orange"
                          name="city"
                          label="City"
                          rules={[
                            {
                              required: true,
                              message: "City is required",
                            },
                          ]}
                        >
                          <SelectReact
                            isClearable
                            options={City.getCitiesOfState(
                              selectedState?.countryCode,
                              selectedState?.isoCode
                            )}
                            getOptionLabel={(options) => {
                              return options["name"];
                            }}
                            getOptionValue={(options) => {
                              return options["name"];
                            }}
                            value={selectedCity}
                            onChange={(item) => {
                              setSelectedCity(item);
                            }}
                          />
                        </Form.Item>
                      </div>

                      <legend className="para b themeCol-orange mx-3">
                        Gallery:{" "}
                      </legend>
                      <div className="upload">
                        <Form.Item
                          className=""
                          name="galleryimage1"
                          label="Image 1"
                          rules={[
                            {
                              required: true,
                              message: "Gallery image is required",
                            },
                          ]}
                        >
                          <Upload
                            maxCount={1}
                            beforeUpload={customBeforeUpload}
                            accept=".jpeg,.png,.jpg"
                          >
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>

                      <div className="upload">
                        <Form.Item
                          className=""
                          name="galleryimage2"
                          label="Image 2"
                          rules={[
                            {
                              required: true,
                              message: "Gallery Image is required",
                            },
                          ]}
                        >
                          <Upload
                            maxCount={1}
                            accept=".jpeg,.png,.jpg"
                            beforeUpload={customBeforeUpload}
                          >
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>

                      <legend className="para b themeCol-orange mx-3">
                        Supportive Document:{" "}
                      </legend>
                      <div className="upload">
                        <Form.Item
                          className=""
                          name="Supportivedocument1"
                          label="Supportive Document 1"
                          rules={[
                            {
                              required: true,
                              message: "supporitive documentis required",
                            },
                          ]}
                        >
                          <Upload
                            maxCount={1}
                            accept=".jpeg,.png,.jpg,.pdf"
                            beforeUpload={customBeforeUploadwithpdf}
                          >
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>

                      <div className="upload">
                        <Form.Item
                          className=""
                          name="Supportivedocument2"
                          label="Supportive Document 2"
                          rules={[
                            {
                              required: true,
                              message: "supportive document is required",
                            },
                          ]}
                        >
                          <Upload
                            maxCount={1}
                            accept=".jpeg,.png,.jpg,.pdf"
                            beforeUpload={customBeforeUploadwithpdf}
                          >
                            <Button block icon={<UploadOutlined />}>
                              Click to upload
                            </Button>
                          </Upload>
                        </Form.Item>
                      </div>

                      <div className="d-flex justify-content-center">
                        <Button
                          htmlType="submit"
                          className="btnnca btn-gold signupbtn"
                        >
                          Submit for Review
                        </Button>
                      </div>
                    </Form>
                  </div>
                </TabPane>
              </Tabs>
            </div>
          </section>
        </div>
      </div>

      <Modal
        open={isModalOpen}
        closeIcon={false}
        onOk={handleOk}
        centered={true}
        footer={[
          <div className="d-flex justify-content-center mt-3">
            <Button key="ok" type="primary" onClick={handleOk}>
              OK
            </Button>
          </div>,
        ]}
      >
        <div className="d-flex justify-content-center">
          <img
            src={require("./../../assets/icons/check-icon.png")}
            alt="sucess-icon"
            className="sucessiconimg"
          />
        </div>
        <div className="d-flex justify-content-center mt-3">
          Cause created successfully and sent for admin approval.
        </div>
        <div className="d-flex justify-content-center mt-2 b"> Thank you!</div>
      </Modal>
    </>
  );
}

export default CauseDetails;
