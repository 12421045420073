import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import UploadService from '../../service/file-upload.service';
import { Table, Tag, Pagination, Modal, Form, Button, Input, Tooltip, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
function Corp_ConceptNoteAgreement() {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(8);
  const [totalElements, setTotalElements] = useState(0);
  const [dataSource, setDataSource] = useState([]);
  const [recordData, setRecordData] = useState([]);
  const [refreshPage, setRefreshPage] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false); // Modal visibility state
  const [fileList, setFileList] = useState([]); // Store the uploaded file list
  const userCred = $.parseJSON(sessionStorage.getItem('da_userCred'));
  const userRoleAuthMapping = $.parseJSON(sessionStorage.getItem('da_authMapping'));
  const allowedFileTypes = ["application/pdf"];


  const DownloadConceptNote = (recordData) => {
    const DownloadformData = new FormData();
    DownloadformData.append('userCred', JSON.stringify(userCred));
    DownloadformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    DownloadformData.append('conceptNoteAgreement', JSON.stringify(recordData));
    UploadService.filedownload('corp/conceptNote/agreement/corp/download', DownloadformData)
      .then((response) => {
        viewBlob(response.data);

      })
      .catch((error) => {
        message.error('File Download failed.');
      });

  }

  const DownloadNGOConceptNote = (recordData) => {
    const DownloadNGOformData = new FormData();
    DownloadNGOformData.append('userCred', JSON.stringify(userCred));
    DownloadNGOformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    DownloadNGOformData.append('conceptNoteAgreement', JSON.stringify(recordData));
    UploadService.filedownload('corp/conceptNote/agreement/ngo/download', DownloadNGOformData)
      .then((response) => {
        viewBlob(response.data);

      })
      .catch((error) => {
        message.error('File Download failed.');
      });

  }

  function viewBlob(blob, filename) {
    if (blob.size === 0) {
      message.error("File corrupted.");
      return;
    }
    const url = window.URL.createObjectURL(blob);

    if (blob.type.startsWith("image")) {
      window.open(url, "_blank");
    } else if (blob.type === "application/pdf") {
      window.open(url, "_blank");
    }
    else {
      const a = document.createElement("a");
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
    }

    window.URL.revokeObjectURL(url);
  }

  const UploadConceptNote = (recordData) => {
    setRecordData(recordData);
    setIsModalVisible(true);
  };
  const checkFileType = (file) => {
    const isFileTypeAllowed = allowedFileTypes.includes(file.type);
    if (!isFileTypeAllowed) {
      message.error("You can only upload JPG, JPEG, or PNG files!");
    }
    return isFileTypeAllowed;
  };

  const customBeforeUpload = (file) => {
    const isFileTypeAllowed = checkFileType(file);
    return isFileTypeAllowed ? true : Upload.LIST_IGNORE;
  };

  const onsubmit = (values) => {
    const updatedRecordData = {
      ...recordData,
      corpAgreementFileName: values.Agreement.file.name
    };
    const formData = new FormData();
    formData.append('userCred', JSON.stringify(userCred));
    formData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    formData.append('conceptNoteAgreement', JSON.stringify(updatedRecordData));
    formData.append('file', values.Agreement.file.originFileObj);

    UploadService.create('corp/conceptNote/agreement/upload', formData)
      .then((response) => {
        message.success('File uploaded successfully!');
        setIsModalVisible(false);
        setRefreshPage(!refreshPage);
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        message.error('File upload failed.');
      });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const acceptAgreement = (record) => {
    const agreementformData = new FormData();
    agreementformData.append('userCred', JSON.stringify(userCred));
    agreementformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    agreementformData.append('conceptNoteAgreement', JSON.stringify(record));

    UploadService.create('corp/conceptNote/agreement/approve', agreementformData)
      .then((response) => {
        Modal.info({
          content: (
            <div>
              <p> Agreement Approved sucessfully</p>
            </div>
          ),
        })

      })
      .catch((error) => {
        message.error('Error in Approveing Agreement.');
      });

  }
  const revisionAgreement = (record) => {
    const agreementRevisionformData = new FormData();
    agreementRevisionformData.append('userCred', JSON.stringify(userCred));
    agreementRevisionformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    agreementRevisionformData.append('conceptNoteAgreement', JSON.stringify(record));

    UploadService.create('corp/conceptNote/agreement/requestRevision', agreementRevisionformData)
      .then((response) => {
        Modal.info({
          content: (
            <div>
              <p> Requested Revised Agreement sucessfully</p>
            </div>
          ),
        })

      })
      .catch((error) => {
        message.error('Error in Approveing Agreement.');
      });


  }
  const rejectAgreement = (record) => {
    const agreementrejectformData = new FormData();
    agreementrejectformData.append('userCred', JSON.stringify(userCred));
    agreementrejectformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    agreementrejectformData.append('conceptNoteAgreement', JSON.stringify(record));

    UploadService.create('corp/conceptNote/agreement/reject', agreementrejectformData)
      .then((response) => {
        Modal.warning({
          content: (
            <div>
              <p> Agreement Rejected sucessfully</p>
            </div>
          ),
        })

      })
      .catch((error) => {
        message.error('Error in Approveing Agreement.');
      });

  }
  const CompleteAgreement = (record) => {
    const agreementcompleteformData = new FormData();
    agreementcompleteformData.append('userCred', JSON.stringify(userCred));
    agreementcompleteformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    agreementcompleteformData.append('conceptNoteAgreement', JSON.stringify(record));

    UploadService.create('corp/conceptNote/agreement/complete', agreementcompleteformData)
      .then((response) => {
        Modal.warning({
          content: (
            <div>
              <p> Agreement Process Completed  sucessfully.Proceed to Payment</p>
            </div>
          ),
        })

      })
      .catch((error) => {
        message.error('Error in Approveing Agreement.');
      });

  }

  const Donationcolumns = [
    {
      title: 'Cause',
      dataIndex: 'causeId',
      width: 60,
      align: 'center',
    },
    {
      title: 'Date(Updated)',
      dataIndex: 'updatedDate',
      width: 90,
      align: 'center',
      render: (text) => {
        const datePart = text?.substr(0, 10); 
        return datePart || 'N/A'; 
      },
    },
    {
      title: 'Concept Note Status',
      dataIndex: 'status',
      width: 90,
      align: 'center',
      render: (text) => {
        const statusMap = {
          AGREEMENT_REQUESTED: 'Requested Agreement',
          AGREEMENT_SUBMITTED:"NGO Uploded",
          AGREEMENT_NGO_SIGNED	: 'Application Approved',
          AGREEMENT_REJECTED:"Rejected",
          AGREEMENT_REVISION_REQUESTED:"Revision Requested",
          AGREEMENT_COMPLETED:"Completed Process"
        };
  
        return (
          <Tag color="orange" >
            {statusMap[text] || text}
          </Tag>
        );
      },
    },
    {
      title: 'Upload',
      key: 'Upload',
      width: 90,
      align: 'center',
      render: (record) => (
        <>
        {record.status=="AGREEMENT_REQUESTED" ||record.status=="AGREEMENT_NGO_SIGNED" ||
        record.status== "AGREEMENT_REJECTED" || record.status=="AGREEMENT_COMPLETED"?(
          null
        ):(
          <Tooltip title="Upload">
          <a onClick={() => UploadConceptNote(record)}>
            <button
              className="btn btn-sm text-white agreement"
              style={{ backgroundColor: '#007bff', borderColor: '#007bff' }}
            >
              <i className="fa fa-upload"></i> Upload
            </button>
          </a>
        </Tooltip>
        )}
          
          {record.corpAgreementFileName ? (
            <Tooltip title="View Corp Agreement">
              <a onClick={() => DownloadConceptNote(record)}>
                <button
                  className="btn my-2 btn-sm text-white agreement"
                  style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
                >
                  <i className="fas fa-eye"></i> View
                </button>
              </a>
            </Tooltip>
          ):(<button
            className="btn my-2 btn-sm text-white agreement"
            style={{ backgroundColor: '#6c757d', borderColor: '#6c757d' }}
          >
             <i className="fa fa-ban text-white"></i> No File
          </button>)}
        </>
      ),
    },
    {
      title: 'NGO Agreement',
      key: 'NGO Agreement',
      width: 90,
      align: 'center',
      render: (record) => (
        <>
          {record.ngoAgreementFileName!=null && record.status!="AGREEMENT_REVISION_REQUESTED" ? (
            <Tooltip title="Download NGO Agreement">
              <a onClick={() => DownloadNGOConceptNote(record)}>
                <button
                  className="btn btn-sm text-white agreement"
                  style={{ backgroundColor: '#28a745', borderColor: '#28a745' }}
                >
                  <i className="fa fa-download"></i> Download
                </button>
              </a>
            </Tooltip>
          ):(
            <Tooltip title="Waiting for NGO to Upload Agreement">
                <button
                  className="btn btn-sm text-white agreement"
                  style={{ backgroundColor: '#28a745', borderColor: '#28a745' }}
                >
                   <i className="fa fa-ban text-white"></i> Waiting
                </button>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      width: 90,
      align: 'center',
      render: (record) => {
        const { status } = record;
        const isSpecialStatus = [
          'AGREEMENT_REQUESTED',
          'AGREEMENT_COMPLETED',
          'AGREEMENT_REVISION_REQUESTED',
          'AGREEMENT_REJECTED',		
          'AGREEMENT_NGO_SIGNED',

        ].includes(status);
  
        if (isSpecialStatus) {
          return (
            <>
              <span className="btn btn-green btn-sm para me-1">
                <i className="fas fa-ban text-white"></i>
              </span>
              <span className="btn btn-red btn-sm para me-1">
                <i className="fas fa-ban text-white"></i>
              </span>
              <span className="btn btn-orange btn-sm para me-1">
                <i className="fas fa-ban text-white"></i>
              </span>
              <span className="btn btn-green btn-sm para me-1">
                <i className="fas fa-ban text-white"></i>
              </span>
            </>
          );
        }
  
        return (
          <div className="p-2 d-flex justify-content-between align-items-center">
            <div>
              <button
                className="btn btn-green btn-sm my-2 agreement"
                onClick={() => acceptAgreement(record)}
              >
                <i className="fa fa-check text-white"></i> Approve
              </button>
              <button
                className="btn btn-orange btn-sm agreement"
                onClick={() => revisionAgreement(record)}
              >
                <i className="fa fa-plus text-white"></i> Send Revision
              </button>
            </div>
            <div>
              <button
                className="btn btn-red btn-sm my-2 agreement"
                onClick={() => rejectAgreement(record)}
              >
                <i className="fa fa-ban text-white"></i> Reject
              </button>
              <button
                className="btn btn-green btn-sm agreement"
                onClick={() => CompleteAgreement(record)}
              >
                <i className="fa fa-lock text-white"></i> Complete
              </button>
            </div>
          </div>
        );
      },
    },
  ];
  

  const handlePageChange = (page) => {
    setPage(page);
  };

  useEffect(() => {
    const ConceptNoteformData = new FormData();
    ConceptNoteformData.append('userCred', JSON.stringify(userCred));
    ConceptNoteformData.append('userRoleAuthMapping', JSON.stringify(userRoleAuthMapping));
    ConceptNoteformData.append('page', JSON.stringify(page - 1)); // Correct 0-indexed page
    ConceptNoteformData.append('size', JSON.stringify(size));


    UploadService.create('corp/conceptNote/agreements/get', ConceptNoteformData)
      .then((response) => {
        const { content, totalElements } = response.data.data.conceptNoteAgreements;
        setDataSource(content);
        setTotalElements(totalElements);
        // console.log(response.data)
      })
      .catch((error) => {
        console.error('Error fetching concept note data:', error);
      });
  }, [page, size, refreshPage]);

  return (
    <div className="col-md-12 row fontfamilynca">
      <section className="content">
        <div className="row">
          <div className="mt-1">
            <Table
              columns={Donationcolumns}
              dataSource={dataSource}
              style={{ padding: '5px' }}
              pagination={false}
              bordered
              scroll={{ x: 1000 }}
              className="custom-table"
            />
            <Pagination
              current={page}
              pageSize={size}
              onChange={handlePageChange}
              total={totalElements}
              showSizeChanger={false}
            />
          </div>
        </div>
      </section>
      <Modal
        title="Upload Concept Note Agreement"
        visible={isModalVisible}
        closeIcon={false}
        footer={null} // Hide default footer
        onCancel={handleModalCancel} // Close on cancel
      >
        <Form onFinish={onsubmit}>
          <Form.Item
            name="Agreement"
            label="Agreement PDF"
            className='mt-4'
            rules={[{ required: true, message: "Agreement PDF is required" }]}
          >
            <Upload
              maxCount={1}
              accept=".pdf"
              beforeUpload={customBeforeUpload}
              onChange={handleFileChange}
              fileList={fileList} // Bind the file list here
            >
              <Button block icon={<UploadOutlined />}>
                Click to upload
              </Button>
            </Upload>
          </Form.Item>
          <div className="d-flex justify-content-center mt-4">
            <Button
              key="ok"
              className='mx-2 btn-green'
              htmlType="submit"
              icon={<i className="fas fa-check-circle" style={{ marginRight: 8 }} />}
            >
              Submit
            </Button>
            <Button
              key="cancel"
              className=' btn-red'
              onClick={handleModalCancel}
              icon={<i className="fas fa-times-circle" style={{ marginRight: 8 }} />}
            >
              Close
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  )
}

export default Corp_ConceptNoteAgreement